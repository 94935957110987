import isaac from "../Assests/isaac.jpg";
/* import tebify from "../Assests/tebify.jpg";
import esther from "../Assests/esther.jpg";
import damilola from "../Assests/damilola.jpg"; */
import peter from "../Assests/peter.jpg";
import daniel from "../Assests/daniel.jpg";

import mohpheth from "../Assests/mohpheth.jpg";

export const team = [
  {
    id: "1",
    pic: <img src={isaac} alt="..." />,
    name: "Isaac",
    position: "Founder",
    role: "Fullstack Developer",
  },
  /* {
    id: "2",
    pic: (
      <img
        src={tebify}
        alt="..."
       
      />
    ),
    name:"Justice",
     role: " Fullstack developer "
  }, 
  {
    id: "3",
    pic: (
      <img
        src={esther}
        alt="..."
      
      />
    ),
    name:"Esther",
     role: " UI/UX designer"
  },
 
  {
    id: "5",
    pic: (
      <img
        src={damilola}
        alt="..."
       
      />
    ),
    name:"Damilola",
     role: " Frontend developer "
  },*/
  {
    id: "2",
    pic: <img src={peter} alt="..." />,
    name: "Peter",
    role: " Fullstack Developer ",
  },
  {
    id: "3",
    pic: <img src={mohpheth} alt="..." />,
    name: "Mohpheth",
    role: "Software Engineer",
  },
  {
    id: "4",
    pic: <img src={daniel} alt="..." />,
    name: "Daniel",
    role: "Frontend Engineer",
  },
];
